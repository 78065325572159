<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="is-pulled-right">
            <b-field grouped>
              <b-button type="is-primary"
                icon-left="download"
                :disabled="disabled"
                :loading="loading"
                @click="load()">
                Jooksuta
              </b-button>
            </b-field>
          </div>
          <h1 class="title">
            Erinevused leiulogide andmetes
          </h1>
          <h2 class="subtitle">
            GP / GC Eesti aardelogide erinevused
          </h2>
        </div>
      </div>
    </section>
    <section v-if="data.length">
      <div class="container compare-table">
        <section class="modal-card-body">
          <h3 class="subtitle mt-5">
            Vigaste leiulogide arv ({{ data.length }}). Võrreldakse: {{ fieldNames().join(', ') }}
          </h3>
          <b-table
            :data="data"
            sortable
            bordered
            narrowed
            hoverable
            detailed
            paginated
            per-page="50"
            default-sort="code"
            :mobile-cards="true"
            custom-detail-row
            :opened-detailed="data.map(item => item.code)"
            detail-key="code"
            show-detail-icon>
            <template v-for="col in columns">
              <b-table-column :key="col.field" v-bind="col" cell-class="cell">
                <template v-slot="props">
                  <common-list-cell
                    :col="col"
                    :row="props.row">
                  </common-list-cell>
                </template>
              </b-table-column>
            </template>
            <template slot="detail" slot-scope="props">
              <tr v-for="(item, i) in props.row.items" :key="'d-' + props.row.code + i " class="detail has-background-warning-light">
                <td>{{ item.provider.toUpperCase() }}</td>
                <td v-for="col in columns" :key="'d' + i + col.field" class="cell">
                  <common-list-cell
                    :col="col"
                    :row="item">
                  </common-list-cell>
                </td>
              </tr>
            </template>
          </b-table>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import apiService from '@/services/ApiService'
import { error } from '@/utils'
import CommonListCell from '@/components/CommonListCell.vue'

export default {
  name: 'CommonList',
  components: {
    CommonListCell
  },
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    const providers = []
    if (data && data['gp']) {
      providers.push('gp')
    }
    if (data && data['gc']) {
      providers.push('gc')
    }
    return {
      providers: providers,
      disabled: providers.length === 0,
      loading: false,
      fields: [],
      data: [],
      columns: [
        {
          field: 'code',
          label: 'Kood',
          searchable: true,
          sortable: true,
          width: 60
        },
        {
          field: 'name',
          label: 'Aare',
          searchable: true,
          sortable: true
        },
        {
          field: 'time',
          label: 'Leitud',
          sortable: true,
          width: 110
        },
        {
          field: 'by',
          label: 'Leidja',
          searchable: true,
          sortable: true
        }
      ]
    }
  },
  methods: {
    load () {
      this.disabled = true
      this.loading = true

      apiService.loadLogsCommonList()
        .then(response => {
          if (response.ok) {
            this.fields = response.data.fields
            this.data = response.data.compare.map(item => {
              const row = {
                items: [{ 'has-error': {}}],
                'has-error': {}
              }
              Object.keys(item).forEach(key => {
                row[key] = item[key]['gp']
                row.code = item.cache['gp'].code
                row.name = item.cache['gp'].name
                row.items[0][key] = item[key]['gc']
                row.items[0].code = item.cache['gc'].code
                row.items[0].name = item.cache['gc'].name
                row['has-error'][key] = item._invalid.includes(key)
                row.items[0]['has-error'][key] = item._invalid.includes(key)
              })
              return row
            })
          }
        })
        .catch(error)
        .finally(() => {
          this.disabled = false
          this.loading = false
        })
    },
    fieldNames() {
      return this.columns.filter(col => this.fields.includes(col.field)).map(col => col.label)
    }
  }
}
</script>

<style>

.compare-table table {
  height: 1px;
}
.compare-table table tr {
  height: 100%;
}
.compare-table table tr td.cell {
  height: 100%;
  padding: 0;
}
.compare-table table tr td.cell > div {
  height: 100%;
  padding: 0.25em 0.5em;
}
.compare-table table tr.detail td.cell > div.error {
  filter: brightness(95%)
}
.compare-table table tr.detail td {
  border-bottom-width: 3px;
  border-bottom-color: #bbb
}

</style>

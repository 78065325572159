<template>
  <div :class="`${cellClass(row, col)}`">
    <span v-if="col.addon && col.addon.length" class="addon">
      <span v-for="key in col.addon" :key="row.code + key + '_addon'">
        <b-radio
          v-if="key === 'status'"
          size="is-small"
          checked="true"
          :type="statusType(row[key])">
        </b-radio>
        <b-icon
          v-else-if="key === 'premium'"
          :icon="row[key] ? 'lock-outline' : 'lock-open-variant-outline'"
          size="is-small">
        </b-icon>
      </span>
    </span>
    <a
      v-if="col.field === 'coordinates'"
      :href="getMapUrl(row[col.field])"
      target="_blank">
      {{ row[col.field].map(x => x.toFixed(5)).join(', ') }}
    </a>
    <a
      v-else-if="col.field === 'name'"
      :href="row.url"
      target="_blank">
      {{ row.name }}
    </a>
    <span
      v-else-if="col.field === 'provider'">
      <a
        :href="row.url"
        target="_blank">
        {{ row.provider }}
      </a>
      <a
        v-for="key in objectKeys(row['providers'])"
        :key="row.code + key"
        :href="row['providers'][key]"
        target="_blank">
        {{ key }}
      </a>
    </span>
    <a
      v-else-if="col.field === 'log' && row.log"
      :href="row.log"
      target="_blank">
      Logi ära
    </a>
    <span v-else-if="col.field === 'time'">
      {{ row[col.field] ? formatDate(row[col.field]) : '' }}
    </span>
    <span v-else-if="col.field === 'status'">
      <b-field>
        <b-radio
          size="is-small"
          checked="true"
          :type="statusType(row[col.field])">
          {{ row[col.field] }}
        </b-radio>
      </b-field>
    </span>
    <span v-else-if="col.field === 'premium'">
      <b-icon
        :icon="row[col.field] ? 'lock-outline' : 'lock-open-variant-outline'"
        size="is-small">
      </b-icon>
      {{ row[col.field] }}
    </span>
    <span v-else>{{ row[col.field] }}</span>
  </div>
</template>

<script>
import { templator, formatDate } from '@/utils'

export default {
  name: 'CommonListCell',
  props: {
    row: Object,
    col: Object
  },
  methods: {
    getMapUrl(coords) {
      return templator(this.$config.map.url, {
        lat: coords[1],
        lon: coords[0]
      })
    },
    cellClass(row, col) {
      const classes = []
      if (col.numeric) {
        classes.push('has-text-right')
      }
      if (row['has-error'] && row['has-error'][col.field]) {
        classes.push('has-background-danger-light')
        classes.push('error')
      }
      return classes.join(' ')
    },
    formatDate(date) {
      return formatDate(date)
    },
    statusType(status) {
      let type = ''
      switch (status) {
        case 'active':
          type = 'is-success'
          break
        case 'disabled':
          type = 'is-warning'
          break
        case 'archived':
          type = 'is-danger';
          break
      }
      return type
    },
    objectKeys(obj) {
      if (obj) {
        return Object.keys(obj)
      }
      return []
    }
  }
}
</script>
